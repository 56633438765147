import React from "react"
import Helmet from 'react-helmet'

import Layout from "../components/layout"
import Header from "../components/header"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Marine Serre Archive" />
    <Helmet
      bodyAttributes={{
          class: 'countdown-page'
      }}
    />
    <Header siteTitle="Marine Serre Archive" />
    <div className="page__content fs-large" style={{marginTop: '5rem'}}>is now closed.</div>
  </Layout>
)

export default IndexPage
